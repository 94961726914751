import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface MediaAndContentLayoutProps {
  media: ReactNode
  content: ReactNode
  orientation: 'left' | 'right'
}

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  gap: 72px;
  align-items: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 100%;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 24px;
  }
`

const MediaSection = styled.div<{ $isLeft: boolean }>`
  max-width: 670px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  order: ${({ $isLeft }) => ($isLeft ? '0' : '1')};
  border-radius: 30px;

  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 100%;
    flex: 1;
    height: auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    order: -1;
  }
`

const ContentSection = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 100%;
    flex: 1;
    height: auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex: 1;
    margin-left: 0;
    margin-right: 0;
  }
`

export const MediaAndContentLayout = ({ orientation = 'left', media, content }: MediaAndContentLayoutProps) => (
  <StyledContainer>
    <MediaSection $isLeft={orientation === 'left'}>{media}</MediaSection>
    <ContentSection>{content}</ContentSection>
  </StyledContainer>
)
