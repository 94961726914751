import { HomePageRecord } from 'types'
import { useEffect, useState } from 'react'
import { HomePage } from '@/components/pages/home/HomePage'
import { Homepage2024 } from '@/components/pages/homepage2024/Homepage2024'
import { useKameleoonApi } from '@/lib/kamaleoon/useKameleoonApi'
import { HomepageLoadingSkeleton } from '@/components/pages/homepage2024/content/HomepageLoadingSkeleton'
import { useExperimentContext } from '@/context/ExperimentContext'

type HomePageExperimentProps = {
  pageContentOriginal: HomePageRecord
  pageContentNew: any
}

const EXPERIMENT_NAME = 'HOME-EXP2 New homepage v4'

export const HomePageExperiment: React.FC<HomePageExperimentProps> = ({ pageContentNew, pageContentOriginal }) => {
  const { setVariant } = useExperimentContext()
  const { waitForAssociatedVariation } = useKameleoonApi()
  const [selectedVariant, setSelectedVariant] = useState<string | null>(null)

  useEffect(() => {
    const getVariant = async () => {
      const variant = await waitForAssociatedVariation(EXPERIMENT_NAME)
      if (variant) {
        setSelectedVariant(variant.name)
        setVariant(variant.name)
      } else {
        setSelectedVariant('control')
        setVariant('control')
      }
    }
    getVariant()
    return () => {
      setVariant(null)
    }
  }, [waitForAssociatedVariation])

  if (!selectedVariant) {
    return <HomepageLoadingSkeleton />
  }

  if (selectedVariant === 'New homepage' || selectedVariant === 'Image first new homepage') {
    return <Homepage2024 localisedPages={pageContentNew} />
  }
  return <HomePage pageContent={pageContentOriginal} />
}
