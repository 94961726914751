import Image from 'next/image'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

const MOBILE_SCREEN_SMALL = '450px'

const StyledLinksContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 64px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: 100%;
    padding-top: 56px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-top: 48px;
  }

  @media screen and (max-width: ${MOBILE_SCREEN_SMALL}) {
    padding-top: 40px;
  }
`

const StyledHelpContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 2;
  text-align: left;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`

const StyledHelp = styled.div`
  flex-grow: 1;
  margin-bottom: 48px;

  h2 {
    margin: 0 0 16px;
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
    color: ${(props) => props.theme.v2.surface.dark};
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      margin-bottom: 8px;
      font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};

      a {
        color: ${(props) => props.theme.v2.interactive.bright};

        &:hover {
          color: ${(props) => props.theme.v2.interactive.subdued};
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        margin-bottom: 12px;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    h2 {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }
  }

  @media screen and (max-width: ${MOBILE_SCREEN_SMALL}) {
    margin-bottom: 32px;
  }
`

const StyledSocial = styled.div`
  flex-grow: 2;

  @media screen and (max-width: ${MOBILE_SCREEN_SMALL}) {
    order: -1;
    flex: 1 0 100%;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    padding: 0;
    margin: 0;

    @media screen and (max-width: ${MOBILE_SCREEN_SMALL}) {
      order: -1;
      flex: 1 0 100%;
      justify-content: flex-start;
      margin-bottom: 48px;
    }

    li {
      list-style: none;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`

const trackItemClick = (label: string) => {
  trackCtaClick('footer', label, { link_position: 'footer' })
}

export const FooterBody: React.FC = () => {
  const router = useRouter()

  // Check if the current pathname includes 'daily30'
  const showDaily30TermsLink = router.pathname.includes('daily30')

  return (
    <StyledLinksContainer>
      <StyledHelpContainer>
        <StyledHelp className="first">
          <h2>Company</h2>
          <ul>
            <li>
              <Link href="/about-zoe" onClick={() => trackItemClick('About us')}>
                About us
              </Link>
            </li>
            <li>
              <Link
                href="https://www.notion.so/joinzoe/Work-at-ZOE-8b8954cb8b50419e8f7d87b9ea176e1f"
                onClick={() => trackItemClick('Careers')}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link href="/terms" onClick={() => trackItemClick('Terms of Service')}>
                Terms of Service
              </Link>
            </li>
            {showDaily30TermsLink && (
              <li>
                <Link href="/daily30/terms" onClick={() => trackItemClick('Terms of Service')}>
                  Terms of Service (Daily30+)
                </Link>
              </li>
            )}
            <li>
              <Link href="/privacy-policy" onClick={() => trackItemClick('Privacy policy')}>
                Privacy policy
              </Link>
            </li>
            <li>
              <Link href="/cookie-policy" onClick={() => trackItemClick('Cookie policy')}>
                Cookie policy
              </Link>
            </li>
          </ul>
        </StyledHelp>

        <StyledHelp className="second">
          <h2>Resources</h2>
          <ul>
            <li>
              <Link href="/faqs" onClick={() => trackItemClick('Help')}>
                FAQs
              </Link>
            </li>
            <li>
              <Link href="/learn" onClick={() => trackItemClick('Blog')}>
                Blog
              </Link>
            </li>
            <li>
              <Link href="/research" onClick={() => trackItemClick('Research updates')}>
                Research updates
              </Link>
            </li>
            <li>
              <Link href={`${process.env.HEALTH_STUDY_URL}`} onClick={() => trackItemClick('ZOE Health Study')}>
                ZOE Health Study
              </Link>
            </li>
          </ul>
        </StyledHelp>
      </StyledHelpContainer>

      <StyledSocial>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/zoe/"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackItemClick('instagram-ink.svg')}
            >
              <Image src="/assets/img/icons/instagram-ink.svg" alt="ZOE on Instagram" width={32} height={32} />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/joinzoe1/"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackItemClick('facebook-ink.svg')}
            >
              <Image src="/assets/img/icons/facebook-ink.svg" alt="ZOE on Facebook" width={32} height={32} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/join_zoe"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackItemClick('twitter-ink.svg')}
            >
              <Image src="/assets/img/icons/twitter-ink.svg" alt="ZOE on Twitter" width={32} height={32} />
            </a>
          </li>
          <li>
            <a href="mailto:hello@joinzoe.com" onClick={() => trackItemClick('email-ink.svg')}>
              <Image src="/assets/img/icons/email-ink.svg" alt="Email Zoe" width={32} height={32} />
            </a>
          </li>
        </ul>
      </StyledSocial>
    </StyledLinksContainer>
  )
}
