import React from 'react'
import styled from 'styled-components'
import { EmailSignupForm } from '@/elements/signup/EmailSignupForm'
import { Section } from '@/lib/gtm/typesV2'

const StyledNewsletterSignup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0 240px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.v2.surface.yellow};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 0 0 64px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 48px 100px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;
    align-items: center;
    padding: 30px 24px 40px;
    display: flex;
    flex-direction: column-reverse;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 327px;

  h2 {
    font-size: ${(props) => props.theme.v2.typography.display.xxs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xxs.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    max-width: 343px;
    margin-bottom: 8px;
  }

  p {
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    max-width: 382px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    h2 {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      max-width: 327px;
    }

    p {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: 28px;
      max-width: 327px;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.containerWide}) {
    h2 {
      font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
      max-width: 327px;
      min-width: 250px;
    }
    p {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: 28px;
      max-width: 327px;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    h2 {
      font-size: ${(props) => props.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xxs.lineHeight};
      max-width: 327px;
    }
    p {
      font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
      line-height: 28px;
      max-width: 327px;
      margin-bottom: 30px;
    }
  }
`

const StyledEmailContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 115px;
  max-width: 381px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding-left: 64px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-left: 64px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.containerWide}) {
    padding-left: 36px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 40px;
    padding-left: unset;
    width: 327px;
    margin: 0;
  }

  form {
    display: flex;
    background-color: white;
    border-radius: 8px;
    width: 380px;
    min-width: 260px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.containerWide}) {
      max-width: 300px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      max-width: unset;
      min-width: unset;
      width: 327px;
    }

    input[type='email'] {
      flex: 1;
      overflow: hidden;
      border: none;
      background-color: transparent;
      padding: 21px 10px 21px 16px;
      font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};

      &:focus {
        outline: none;
      }
    }

    input[type='submit'] {
      flex: 0 1 auto;
      border: none;
      background-color: transparent;
      font-size: ${(props) => props.theme.v2.typography.label.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.label.l.lineHeight};
      padding-right: 16px;
      cursor: pointer;
      color: ${(props) => props.theme.v2.interactive.bright};
      &:hover {
        color: ${(props) => props.theme.v2.interactive.subdued};
      }
    }
  }

  p {
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
  }
`
const StyledImage = styled.img`
  max-width: 22vw;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`
const StyledImageMobile = styled.img`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 70%;
    margin: 0 auto;
    display: flex;
  }
`

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`

type NewsletterSignupProps = {
  section: Section
}

export const FooterNewsletterSignup: React.FC<NewsletterSignupProps> = ({ section }) => {
  return (
    <StyledNewsletterSignup className="newsletter-signup">
      <StyledContainer>
        <StyledContentContainer>
          <h2>Get the latest nutrition tips from world-leading scientists for free.</h2>
          <p>Sign up to our newsletter and be the first to know about special offers, discounts, and new products.</p>
        </StyledContentContainer>
        <StyledEmailContainer>
          <EmailSignupForm hideFormOnSubmit={true} section={section} linkPosition="footer" emailList="newsletter" />
        </StyledEmailContainer>
      </StyledContainer>
      <StyledImageContainer>
        <StyledImage
          className="newsletter-signup-img"
          alt="Background image - we are thriving when we have a healthy diet that suits our unique body."
          src="/assets/img/newsletter-signup/thrive.png"
        />
        <StyledImageMobile
          className="newsletter-signup-img"
          alt="Background image - we are thriving when we have a healthy diet that suits our unique body."
          src="/assets/img/newsletter-signup/thrive-mobile.png"
        />
      </StyledImageContainer>
    </StyledNewsletterSignup>
  )
}
