import { NUTRITION_NEWSLETTER_EMAIL_SUBMITTED } from '@/lib/braze/brazeConstants'
import { registerBrazeUserWithEmailFromWebForm } from '@/lib/braze/brazeUtils'
import { trackSignupEvent } from '@/lib/gtm/gtmV2'
import { EmailListType, LinkPosition, Section } from '@/lib/gtm/typesV2'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { FormEvent, MutableRefObject, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { QUIZ_ID_COOKIE_NAME } from '@/lib/checkoutConstants'

type EmailSignupFormProps = {
  hideFormOnSubmit?: boolean
  section: Section
  linkPosition?: LinkPosition
  emailList: EmailListType
}
export const EmailSignupForm = ({ hideFormOnSubmit, section, linkPosition, emailList }: EmailSignupFormProps) => {
  const emailEl = useRef() as MutableRefObject<HTMLInputElement>
  const [message, setMessage] = useState('')
  const [brazeMessage, setBrazeMessage] = useState('')
  const [cookies] = useCookies([QUIZ_ID_COOKIE_NAME])
  const { isReady, geoLocCountry } = useCheckoutCountry()

  const subscribeCallback = (success: boolean, message?: string) => {
    if (!success && message) {
      setBrazeMessage(message)
    }
  }

  const newsletterSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const email = emailEl.current.value
    if (email) {
      const marketingConsent = true
      const additionalFields = {
        ...(cookies.cUserId && { quizUserId: cookies.cUserId }),
        ...(isReady && geoLocCountry && { country: geoLocCountry }),
      }

      await registerBrazeUserWithEmailFromWebForm(
        e,
        email,
        NUTRITION_NEWSLETTER_EMAIL_SUBMITTED,
        subscribeCallback,
        marketingConsent,
        Object.keys(additionalFields).length !== 0 ? additionalFields : undefined,
      )
      setMessage('Thank you for signing up!')
    }

    trackSignupEvent({ section, emailList, linkPosition, email })
  }

  return hideFormOnSubmit && message ? (
    <p>{message}</p>
  ) : (
    <>
      <form
        method="post"
        onSubmit={(e) => {
          newsletterSubmit(e)
        }}
      >
        <input id="email-input" name="EMAIL" placeholder="Enter your email" ref={emailEl} required type="email" />
        <input type="submit" value="Subscribe" />
      </form>
      {message && <p>{message}</p>}
      {brazeMessage && <p>{brazeMessage}</p>}
    </>
  )
}
