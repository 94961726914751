import { theme } from 'styles/theme'

export interface IconProps {
  colour: string
}
export const defaultProps = {
  colour: 'brand', // from styles/theme.ts theme.colours
}

export const defaultPropsV2 = {
  colour: theme.v2.surface.dark,
}
