import { LogoColour } from '@/components/shared/Logo'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { useEffect, useState } from 'react'
import { CheckoutCountry } from '@/lib/checkout/types'
import { DSNavigation } from '@/components/ds/Navigation/Navigation'
import { trackCtaClick } from '@/lib/gtm/gtmV2'

const getCTAButton = (allowedCheckoutCountries: CheckoutCountry[], checkoutCountry?: CheckoutCountry) => ({
  href:
    checkoutCountry && allowedCheckoutCountries.includes(checkoutCountry)
      ? `/quiz?country=${checkoutCountry}`
      : '/signup',
  label: 'Join ZOE',
})

export type HeaderProps = {
  iconColour?: LogoColour
  backgroundColor?: string
  minimal?: boolean
  withBorder?: boolean
  featuredBanner?: boolean
  bannerHeight?: number | null
  onDarkBackground?: boolean
  hideCta?: boolean
  ctaText?: string
  ctaHref?: string
  onCtaPress?: () => void
}

export const Header = ({
  iconColour = 'brand',
  backgroundColor = 'transparent',
  minimal,
  featuredBanner = false,
  bannerHeight,
  onDarkBackground = false,
  hideCta = false,
  ctaText,
  ctaHref,
  onCtaPress,
}: HeaderProps) => {
  const { isReady, checkoutCountry, allowedCheckoutCountries } = useCheckoutCountry()

  const [cta, setCta] = useState({ label: '', href: '' })

  useEffect(() => {
    if (isReady) {
      setCta(getCTAButton(allowedCheckoutCountries, checkoutCountry))
    }
  }, [isReady, checkoutCountry, allowedCheckoutCountries])

  const trackNavItemClick = (label: string) => {
    trackCtaClick('navbar_header', label, { link_position: 'header' })
  }

  const trackNavCtaClick = () => {
    trackCtaClick('navbar_header', ctaText || cta.label, { link_position: 'header' })
  }

  return (
    <>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NFJ9482N"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>
      <DSNavigation
        menuLabel="Menu"
        logoVariant={iconColour === 'dark' ? 'primary' : 'brand'}
        links={[
          {
            href: '/how-it-works',
            onPress: () => trackNavItemClick('How it works'),
            text: 'How it works',
          },
          {
            text: 'Daily30+',
            href: '/daily30',
            onPress: () => trackNavItemClick('Daily30+'),
            badge: 'New',
          },
          {
            href: '/our-science',
            onPress: () => trackNavItemClick('Science'),
            text: 'Our science',
          },
          {
            href: '/menoscale',
            onPress: () => trackNavItemClick('MenoScale'),
            text: 'MenoScale',
          },
          {
            href: '/learn',
            onPress: () => trackNavItemClick('Learn'),
            text: 'Library',
          },
          {
            href: '/faqs',
            onPress: () => trackNavItemClick('Help'),
            text: 'FAQs',
          },
        ]}
        ctaText={ctaText || cta.label}
        ctaHref={ctaHref || cta.href}
        onCtaPress={onCtaPress || trackNavCtaClick}
      />
    </>
  )
}
