import { Copyright } from '@/components/elements/footer/Copyright'
import { FooterBody } from '@/components/elements/footer/FooterBody'
import { FooterNewsletterSignup } from '@/components/elements/footer/FooterNewsletterSignup'
import React from 'react'
import styled from 'styled-components'

const StyledFooterWrap = styled.div<{ $backgroundColour?: string }>`
  background-color: ${(p) => (p.$backgroundColour ? p.$backgroundColour : p.theme.v2.surface.white)};
`

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 240px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 40px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 24px;
  }
`

type FooterProps = {
  showEmailSignUp?: boolean
  minimal?: boolean
  backgroundColour?: string
  bordered?: boolean
  centered?: boolean
}

export const Footer: React.FC<FooterProps> = ({
  showEmailSignUp = true,
  minimal = false,
  centered = false,
  backgroundColour,
}) => {
  return minimal ? (
    <Copyright minimal centered />
  ) : (
    <StyledFooterWrap $backgroundColour={backgroundColour}>
      {showEmailSignUp && <FooterNewsletterSignup section="footer" />}
      <StyledFooter>
        <FooterBody />
        <Copyright />
      </StyledFooter>
    </StyledFooterWrap>
  )
}
