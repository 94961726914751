export const getFirstParamValue = (query: { [key: string]: string | string[] | undefined }, key: string) => {
  const value = query[key]
  return Array.isArray(value) ? value[0] : value
}

export const decorateLinksWithQueryParameters = (link: string, pageUrl: string, urlQueryParameters: object) => {
  const linkUrl = new URL(link, pageUrl)
  Object.keys(urlQueryParameters).forEach((queryParameter) => {
    linkUrl.searchParams.set(queryParameter, urlQueryParameters[queryParameter])
  })
  return linkUrl.toString()
}
