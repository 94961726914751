import { defaultPropsV2, IconProps } from './IconProps'

export const CheckMark = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        d="M8.12447 28.2773H11.0261C11.2931 28.2773 11.4889 28.3485 11.6847 28.5443L13.7497 30.5915C15.6188 32.4784 17.3811 32.4606 19.2503 30.5915L21.3152 28.5443C21.511 28.3485 21.7068 28.2773 21.9917 28.2773H24.8754C27.51 28.2773 28.7739 27.0312 28.7739 24.3788V21.4772C28.7739 21.2102 28.8629 21.0144 29.0587 20.8186L31.0881 18.7536C32.975 16.8845 32.9572 15.1222 31.0881 13.2352L29.0587 11.1881C28.8629 10.9923 28.7739 10.7786 28.7739 10.5116V7.62785C28.7739 4.99325 27.5278 3.71157 24.8754 3.71157H21.9917C21.7068 3.71157 21.511 3.64037 21.3152 3.44456L19.2503 1.41518C17.3811 -0.471726 15.6188 -0.471726 13.7497 1.41518L11.6847 3.44456C11.4889 3.64037 11.2931 3.71157 11.0261 3.71157H8.12447C5.47208 3.71157 4.22598 4.95763 4.22598 7.62785V10.5116C4.22598 10.7786 4.15478 10.9923 3.95897 11.1881L1.91184 13.2352C0.024891 15.1222 0.042693 16.8845 1.91184 18.7536L3.95897 20.8186C4.15478 21.0144 4.22598 21.2102 4.22598 21.4772V24.3788C4.22598 27.0134 5.47208 28.2773 8.12447 28.2773Z"
        fill={props.colour}
      />
      <path
        d="M15.4165 22.6211C15.9505 22.6211 16.3955 22.3719 16.6981 21.8735L22.8396 12.1896C23.0176 11.8692 23.2312 11.5131 23.2312 11.1571C23.2312 10.445 22.5904 10 21.9317 10C21.5223 10 21.1129 10.2314 20.8281 10.712L15.3453 19.5771L12.8887 16.266C12.5148 15.7498 12.1588 15.6252 11.7316 15.6252C11.0196 15.6252 10.5033 16.1771 10.5033 16.8891C10.5033 17.2273 10.6279 17.5656 10.8593 17.8681L14.0457 21.8735C14.4552 22.4075 14.8824 22.6211 15.4165 22.6211Z"
        fill="#2D3246"
      />
    </svg>
  )
}
CheckMark.defaultProps = defaultPropsV2
