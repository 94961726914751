import styled from 'styled-components'

const StyledListWithIcons = styled.ul`
  list-style: none;
  text-align: left;
  padding-left: 0;
`

const StyledListItemWithIcons = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
  line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
  color: ${(props) => props.theme.v2.onSurface.text};

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    margin-right: 8px;
  }
`

type ListItemWithIconProps = {
  children: any
}

type ListWithIconsProps = {
  children: React.ReactElement<typeof ListItemWithIcon>[]
}

export const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({ children }) => {
  return (
    <StyledListItemWithIcons>
      <img src={'/assets/img/icons/shield.svg'} width="24px" />
      <span>{children}</span>
    </StyledListItemWithIcons>
  )
}

export const ListWithIcons: React.FC<ListWithIconsProps> = ({ children }) => {
  return <StyledListWithIcons>{children}</StyledListWithIcons>
}
